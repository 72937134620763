import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
class ErrorBoundary extends Component {
    state = {
        error: null,
    };
    resetErrorBoundary = () => {
        this.setState({ error: null });
    };
    static getDerivedStateFromError(error) {
        return { error };
    }
    render() {
        const { children, fallback: Fallback } = this.props;
        const { error } = this.state;
        if (error) {
            return _jsx(Fallback, { error: error, resetErrorBoundary: this.resetErrorBoundary });
        }
        return children;
    }
}
export default ErrorBoundary;
