import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import Button from '@Components/common/Button/Button';
import { ROUTES_PATH } from '@Constants/routes';
import { useMemberInfo } from '@Contexts/MemberInfoProvider';
import { useModal } from '@Contexts/ModalProvider';
import LoginModalContents from '../LoginModalContents/LoginModalContents';
const LandingButton = () => {
    const { openModal } = useModal();
    const memberInfo = useMemberInfo();
    const isLogin = !!memberInfo;
    const navigate = useNavigate();
    const handleClickStartButton = () => {
        if (isLogin)
            return navigate(`${ROUTES_PATH.mode}`);
        return openModal(_jsx(LoginModalContents, {}));
    };
    return (_jsx(ButtonContainer, { children: _jsx(Button, { variant: "primary", onClick: handleClickStartButton, "$block": false, size: "small", children: "\uD558\uB8E8\uC2A4\uD130\uB514 \uC2DC\uC791\uD558\uAE30" }) }));
};
export default LandingButton;
const ButtonContainer = styled.div `
  display: flex;
  gap: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
