export const ROUTES_PATH = {
    landing: '/',
    login: '/login',
    auth: '/auth',
    progress: '/progress',
    record: '/record',
    create: '/create',
    preparation: '/preparation',
    participation: '/participation',
    memberRecord: '/member-record',
    mode: '/mode',
    lobby: '/lobby',
};
