import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { styled } from 'styled-components';
import Footer from '@Components/common/Footer/Footer';
import Header from '@Components/common/Header/Header';
import GuideSection from '@Components/landing/GuideSection/GuideSection';
import LandingMainSection from '@Components/landing/LandingMainSection/LandingMainSection';
import MemberProfile from '@Components/landing/MemberProfile/MemberProfile';
import SideLink from '@Components/landing/SideLink/SideLik';
import StartSection from '@Components/landing/StartSection/StartSection';
const Landing = () => {
    return (_jsxs(_Fragment, { children: [_jsxs(LandingHeader, { children: [_jsx(Header, {}), _jsx(MemberProfile, {})] }), _jsxs(LandingContents, { children: [_jsx(LandingMainSection, {}), _jsx(GuideSection, {}), _jsx(StartSection, {})] }), _jsx(Footer, {}), _jsx(SideLink, {})] }));
};
export default Landing;
const LandingHeader = styled.div `
  display: flex;
  align-items: center;

  padding-right: 40px;
`;
const LandingContents = styled.div `
  width: 90%;

  margin: 0 auto;
`;
