import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { styled, css, keyframes } from 'styled-components';
import color from '@Styles/color';
const NotificationItem = ({ remove, type, id, message }) => {
    const [notificationAnimation, setNotificationAnimation] = useState('appear');
    useEffect(() => {
        const removeNotification = setTimeout(() => remove(id), 2000);
        const changeNotificationAnimation = setTimeout(() => setNotificationAnimation('disappear'), 1800);
        return () => {
            clearTimeout(removeNotification);
            clearTimeout(changeNotificationAnimation);
        };
    }, [id, remove]);
    return (_jsx(Layout, { type: type, "$notificationAnimation": notificationAnimation, children: message }, id));
};
export default NotificationItem;
const NOTIFICATION_TYPE = {
    default: css `
    background-color: ${color.neutral[600]};
  `,
    success: css `
    background-color: #239d2d;
  `,
    error: css `
    background-color: #e63f42;
  `,
};
const NotificationAppearAnimation = keyframes `
    0% {
      transform: translateY(20px);
      opacity: 0.2;
    }
  
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;
const NotificationDisappearAnimation = keyframes `
    0% {
      transform: translateY(0);
      opacity: 1;
    }
  
    100% {
      transform: translateY(10px);
      opacity: 0;
    }
  `;
const NOTIFICATION_ANIMATION = {
    appear: css `
    animation: ${NotificationAppearAnimation} 0.2s ease forwards;
  `,
    disappear: css `
    animation: ${NotificationDisappearAnimation} 0.2s ease forwards;
  `,
};
const Layout = styled.div `
  color: ${color.white};

  text-align: center;

  word-break: keep-all;
  white-space: pre-line;

  padding: 10px 20px;
  border-radius: 8px;

  ${({ type, $notificationAnimation }) => css `
    ${NOTIFICATION_TYPE[type]}
    ${NOTIFICATION_ANIMATION[$notificationAnimation]}
  `}
`;
