import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { css, styled } from 'styled-components';
import color from '@Styles/color';
import ChatIcon from '@Assets/icons/ChatIcon';
const OPEN_CHATTING_LINK = 'https://open.kakao.com/o/gDt2u0Hf';
const ChattingLink = () => {
    const [isHoverIcon, setIsHoverIcon] = useState(false);
    return (_jsxs(Layout, { children: [_jsx(GuideMessage, { "$isHoverIcon": isHoverIcon, children: "\uB2E4\uB978 \uC0AC\uB78C\uACFC \uD568\uAED8 \uD559\uC2B5\uD558\uACE0 \uC2F6\uB2E4\uBA74?" }), _jsx(Link, { href: OPEN_CHATTING_LINK, target: "_blank", onMouseEnter: () => setIsHoverIcon(true), onMouseLeave: () => setIsHoverIcon(false), children: _jsx(ChatIcon, { color: "rgba(34,34,34)" }) })] }));
};
export default ChattingLink;
const Layout = styled.div `
  display: flex;
  align-items: center;
  gap: 10px;
`;
const GuideMessage = styled.p `
  font-size: 1.4rem;

  color: ${color.neutral[600]};

  ${({ $isHoverIcon }) => css `
    opacity: ${$isHoverIcon ? '1' : '0'};
  `}

  transition: opacity 0.15s ease;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const Link = styled.a `
  cursor: pointer;

  background-color: ${color.brand.kakao};

  padding: 12px;
  border-radius: 14px;

  svg {
    width: 24px;
    height: 24px;
  }
`;
