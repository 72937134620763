import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from 'styled-components';
import color from '@Styles/color';
const MenuItem = ({ bottomSeparator, children, onClick, hide, ...props }) => {
    const handleClick = () => {
        if (hide)
            hide();
        onClick();
    };
    return (_jsxs(_Fragment, { children: [_jsx(MenuItemLayout, { onClick: handleClick, ...props, children: children }), bottomSeparator && _jsx(Separator, {})] }));
};
export default MenuItem;
const MenuItemLayout = styled.li `
  padding: 6px 24px;

  transition: background-color 0.2s ease;

  cursor: pointer;

  &:hover {
    background-color: ${color.neutral[100]};
  }
`;
const Separator = styled.div `
  height: 1.5px;
  background-color: ${color.neutral[100]};
`;
