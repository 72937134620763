/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
const useFetch = (request, { enabled = true, suspense = true, errorBoundary = true, refetchInterval, onSuccess, onError } = {}) => {
    const [status, setStatus] = useState('pending');
    const [promise, setPromise] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const interval = useRef(null);
    const resolvePromise = useCallback((newResult) => {
        setStatus('fulfilled');
        setResult(newResult);
        onSuccess?.(newResult);
    }, [onSuccess]);
    const rejectPromise = useCallback((error) => {
        setStatus('error');
        setError(error);
        onError?.(error);
    }, [onError]);
    const fetch = useCallback(() => {
        setStatus('pending');
        const requestPromise = request().then(resolvePromise, rejectPromise);
        setPromise(requestPromise);
        return requestPromise;
    }, []);
    const clearResult = () => setResult(null);
    const clearIntervalRef = () => {
        if (interval.current === null)
            return;
        clearInterval(interval.current);
        interval.current = null;
    };
    useEffect(() => {
        if (!enabled)
            return;
        fetch();
        if (refetchInterval) {
            interval.current = setInterval(fetch, refetchInterval);
            return clearIntervalRef;
        }
    }, [enabled, fetch]);
    if (suspense && status === 'pending' && promise) {
        throw promise;
    }
    if (errorBoundary && status === 'error') {
        throw error;
    }
    return { result, isLoading: status === 'pending', error: error, clearResult, refetch: fetch };
};
export default useFetch;
