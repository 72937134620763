import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ErrorBoundary from '@Components/common/ErrorBoundary/ErrorBoundary';
import ErrorFallback from '@Components/common/ErrorFallback/ErrorFallback';
import ScrollToTop from '@Components/common/ScrollToTop/ScrollToTop';
import GlobalStyles from '@Styles/globalStyle';
import { lightTheme } from '@Styles/theme';
import MemberInfoProvider from '@Contexts/MemberInfoProvider';
import ModalProvider from '@Contexts/ModalProvider';
import NotificationProvider from '@Contexts/NotificationProvider';
const App = () => {
    return (_jsxs(ThemeProvider, { theme: lightTheme, children: [_jsx(GlobalStyles, {}), _jsx(NotificationProvider, { children: _jsx(ModalProvider, { children: _jsx(MemberInfoProvider, { children: _jsx(ErrorBoundary, { fallback: ErrorFallback, children: _jsxs(Suspense, { children: [_jsx(ScrollToTop, {}), _jsx(Outlet, {})] }) }) }) }) })] }));
};
export default App;
