import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from 'styled-components';
import Button from '@Components/common/Button/Button';
import Typography from '@Components/common/Typography/Typography';
import color from '@Styles/color';
const Confirm = ({ message, closeModal, onConfirm, onCancel }) => {
    const handleConfirm = () => {
        closeModal();
        onConfirm();
    };
    const handleCancel = () => {
        closeModal();
        onCancel?.();
    };
    return (_jsxs(Layout, { children: [_jsx(Typography, { variant: "p3", children: message }), _jsxs(ButtonContainer, { children: [_jsx(ConfirmButton, { variant: "outlined", size: "x-small", "$block": false, onClick: handleConfirm, children: "\uD655\uC778" }), _jsx(CancelButton, { variant: "outlined", size: "x-small", onClick: handleCancel, children: "\uCDE8\uC18C" })] })] }));
};
export default Confirm;
const Layout = styled.div `
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const ButtonContainer = styled.div `
  display: flex;
  justify-content: end;
  gap: 4px;
`;
const ConfirmButton = styled(Button) `
  width: fit-content;

  border: none;
  color: ${color.blue[500]};

  &:hover:enabled {
    background-color: ${color.blue[50]};
  }
`;
const CancelButton = styled(Button) `
  width: fit-content;

  color: ${color.neutral[500]};

  border: none;
`;
