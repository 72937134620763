import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';
import Menu from '@Components/common/Menu/Menu';
import { ROUTES_PATH } from '@Constants/routes';
import { useMemberInfo, useMemberInfoAction } from '@Contexts/MemberInfoProvider';
import MenuTrigger from '../MenuTrigger/MenuTrigger';
const DEFAULT_MENU_ITEMS = [
    {
        key: 0,
        text: '사용자 피드백',
        onClick: () => {
            window.open('https://forms.gle/gjEejNBaQmbhwh3C8', 'blank');
        },
    },
    {
        key: 1,
        text: 'github',
        onClick: () => {
            window.open('https://github.com/woowacourse-teams/2023-haru-study', 'blank');
        },
    },
];
const MemberProfile = () => {
    const navigate = useNavigate();
    const memberInfo = useMemberInfo();
    const { clearMemberInfo } = useMemberInfoAction();
    const today = new Date();
    if (!memberInfo) {
        return _jsx(_Fragment, {});
    }
    const { name, imageUrl, loginType } = memberInfo;
    const guestMenu = (_jsx(Menu.Item, { onClick: clearMemberInfo, bottomSeparator: true, children: "\uD68C\uC6D0\uC73C\uB85C \uC2DC\uC791\uD558\uAE30" }));
    const memberMenu = (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { onClick: () => navigate(`${ROUTES_PATH.memberRecord}/calendar?year=${today.getFullYear()}&month=${today.getMonth() + 1}`), children: "\uC2A4\uD130\uB514 \uAE30\uB85D" }), _jsx(Menu.Item, { onClick: clearMemberInfo, bottomSeparator: true, children: "\uB85C\uADF8\uC544\uC6C3" })] }));
    return (_jsxs(Menu, { trigger: _jsx(MenuTrigger, { name: name, imageUrl: imageUrl, loginType: loginType }), "$menuListPosition": "left", "$style": css `
        margin: 0 0 0 auto;
        cursor: pointer;
      `, "$menuListStyle": css `
        top: 46px;
      `, children: [loginType === 'guest' ? guestMenu : memberMenu, DEFAULT_MENU_ITEMS.map(({ key, text, onClick }) => (_jsx(Menu.Item, { onClick: onClick, children: text }, key)))] }));
};
export default MemberProfile;
