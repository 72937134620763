import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Landing from '@Pages/Landing';
import NotFound from '@Pages/NotFound';
import { ROUTES_PATH } from '@Constants/routes';
import App from '../App';
const Auth = lazy(() => import('@Pages/Auth'));
const StudyRecord = lazy(() => import('@Pages/StudyRecord'));
const StudyMode = lazy(() => import('@Pages/StudyMode'));
const CreateStudy = lazy(() => import('@Pages/CreateStudy'));
const MemberRecord = lazy(() => import('@Pages/MemberRecord'));
const StudyProgress = lazy(() => import('@Pages/StudyProgress'));
const StudyPreparation = lazy(() => import('@Pages/StudyPreparation'));
const StudyParticipation = lazy(() => import('@Pages/StudyParticipation'));
const StudyLobby = lazy(() => import('@Pages/StudyLobby'));
const router = createBrowserRouter([
    {
        path: ROUTES_PATH.landing,
        element: _jsx(App, {}),
        errorElement: _jsx(NotFound, {}),
        children: [
            {
                index: true,
                element: _jsx(Landing, {}),
            },
            {
                path: ROUTES_PATH.auth,
                element: _jsx(Auth, {}),
            },
            {
                path: `${ROUTES_PATH.progress}/:studyId`,
                element: _jsx(StudyProgress, {}),
            },
            {
                path: `${ROUTES_PATH.record}/:studyId`,
                element: _jsx(StudyRecord, {}),
            },
            {
                path: ROUTES_PATH.create,
                element: _jsx(CreateStudy, {}),
            },
            {
                path: `${ROUTES_PATH.preparation}/:studyId`,
                element: _jsx(StudyPreparation, {}),
            },
            {
                path: ROUTES_PATH.participation,
                element: _jsx(StudyParticipation, {}),
            },
            {
                path: `${ROUTES_PATH.memberRecord}/:mode`,
                element: _jsx(MemberRecord, {}),
            },
            {
                path: ROUTES_PATH.mode,
                element: _jsx(StudyMode, {}),
            },
            {
                path: `${ROUTES_PATH.lobby}/:studyId`,
                element: _jsx(StudyLobby, {}),
            },
        ],
    },
]);
export default router;
