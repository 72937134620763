import { jsx as _jsx } from "react/jsx-runtime";
import { createPortal } from 'react-dom';
import { styled } from 'styled-components';
import NotificationItem from './NotificationItem';
const Notifications = ({ notifications, remove }) => {
    return createPortal(_jsx(Layout, { children: notifications.map((item) => (_jsx(NotificationItem, { ...item, remove: remove }, item.id))) }), document.getElementById('notification-root'));
};
export default Notifications;
const Layout = styled.div `
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  gap: 10px;

  transition: min-height 0.2s ease;

  width: 400px;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;
