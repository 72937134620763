import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from 'styled-components';
import Image from '@Components/common/Image/Image';
import Typography from '@Components/common/Typography/Typography';
import color from '@Styles/color';
import ArrowIcon from '@Assets/icons/ArrowIcon';
import heroImageJpg from '@Assets/images/heroImage.jpg';
import heroImageWebp from '@Assets/images/heroImage.webp';
import LandingButton from '../LandingButton/LandingButton';
const LandingMainSection = () => {
    return (_jsxs("main", { children: [_jsxs(LandingHeader, { children: [_jsxs(LandingContents, { children: [_jsxs(Typography, { variant: "h2", children: [_jsx(Emphasis, { children: "\uD558\uB8E8" }), "\uC2A4\uD130\uB514\uB9CC\uC758", _jsx("br", {}), "\uD559\uC2B5 \uC0AC\uC774\uD074\uC744 \uD1B5\uD574", _jsx("br", {}), "\uD6A8\uC728\uC801\uC73C\uB85C \uD559\uC2B5\uD574\uBCF4\uC138\uC694."] }), _jsx(LandingButton, {})] }), _jsx(HeroImage, { children: _jsx(Image, { originUrl: heroImageJpg, webpUrl: heroImageWebp, alt: "\uBAA9\uD45C, \uD559\uC2B5, \uD68C\uACE0 \uC2A4\uD0ED" }) })] }), _jsxs(LoadMoreContents, { children: [_jsx(Typography, { variant: "p2", children: "\uD558\uB8E8\uC2A4\uD130\uB514\uB9CC\uC758 \uD559\uC2B5 \uC0AC\uC774\uD074\uC774\uB780?" }), _jsx(ArrowIcon, { direction: "down" })] })] }));
};
export default LandingMainSection;
const LandingHeader = styled.div `
  height: calc(100vh - 240px);

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LandingContents = styled.div `
  width: 40%;

  display: flex;
  flex-direction: column;
  gap: 40px;
  align-self: center;

  h2 {
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    justify-self: center;
    text-align: center;

    h2 {
      font-size: 28px;
    }
  }
`;
const HeroImage = styled.div `
  width: 50%;

  @media screen and (max-width: 768px) {
    display: none;
    width: 0;
  }
`;
const LoadMoreContents = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
const Emphasis = styled.span `
  color: ${color.blue[500]};
`;
