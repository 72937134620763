import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';
import color from '@Styles/color';
import { ROUTES_PATH } from '@Constants/routes';
import Typography from '../Typography/Typography';
const Header = () => {
    return (_jsx(Layout, { children: _jsx(Link, { to: ROUTES_PATH.landing, children: _jsxs(Typography, { variant: "h1", "$style": css `
            display: inline;

            font-size: 4rem;
            font-weight: 200;
          `, children: [_jsx(Emphasis, { children: "\uD558\uB8E8" }), "\uC2A4\uD130\uB514"] }) }) }));
};
export default Header;
const Layout = styled.header `
  padding: 40px;

  display: flex;
`;
const Emphasis = styled.span `
  color: ${color.blue[500]};
`;
