import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Children, cloneElement } from 'react';
import { css, styled } from 'styled-components';
import useDisplay from '@Hooks/common/useDisplay';
import useOutsideClick from '@Hooks/common/useOutsideClick';
import MenuItem from './MenuItem';
const MENU_LIST_POSITION = {
    left: css `
    right: 0;
    text-align: end;
  `,
    right: css `
    left: 0;
    text-align: start;
  `,
};
const Menu = ({ $menuListPosition = 'right', $style, children, trigger, $menuListStyle }) => {
    const { isShow, toggleShow, hide } = useDisplay();
    const ref = useOutsideClick(hide);
    return (_jsxs(MenuLayout, { ref: ref, "$style": $style, children: [_jsx(MenuIconWrapper, { onClick: toggleShow, children: trigger }), isShow && (_jsx(MenuList, { "$menuListPosition": $menuListPosition, "$menuListStyle": $menuListStyle, children: Children.map(children, (child) => {
                    const item = child;
                    return cloneElement(item, { hide });
                }) }))] }));
};
export default Menu;
Menu.Item = MenuItem;
const MenuLayout = styled.div `
  position: relative;

  width: fit-content;

  svg {
    cursor: pointer;
  }

  ${({ $style }) => css `
    ${$style}
  `}
`;
const MenuIconWrapper = styled.div `
  padding: 4px;
`;
const MenuList = styled.ul `
  position: absolute;
  top: 34px;

  display: grid;
  row-gap: 6px;

  width: max-content;
  max-height: 80vh;
  overflow: auto;

  padding: 10px 0px;
  border-radius: 8px;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  z-index: 10;

  ${({ $menuListPosition, theme }) => css `
    ${MENU_LIST_POSITION[$menuListPosition]}
    background-color: ${theme.background};
  `}

  ${({ $menuListStyle }) => css `
    ${$menuListStyle && $menuListStyle}
  `}
`;
