import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from '@Hooks/api/useFetch';
import { ROUTES_PATH } from '@Constants/routes';
import tokenStorage from '@Utils/tokenStorage';
import url from '@Utils/url';
import { requestGetMemberInfo, requestPostLogout } from '@Apis/index';
const MemberInfoContext = createContext(null);
const MemberInfoActionContext = createContext(null);
const MemberInfoProvider = ({ children }) => {
    const { result, clearResult, refetch } = useFetch(() => requestGetMemberInfo(), {
        errorBoundary: false,
        enabled: url.getPathName() !== ROUTES_PATH.auth,
    });
    const navigate = useNavigate();
    const memberInfo = result?.data || null;
    const actions = useMemo(() => ({
        refetchMemberInfo: refetch,
        clearMemberInfo: () => {
            tokenStorage.removeAccessToken();
            requestPostLogout();
            clearResult();
            navigate(ROUTES_PATH.landing);
        },
    }), [clearResult, navigate, refetch]);
    return (_jsx(MemberInfoContext.Provider, { value: memberInfo, children: _jsx(MemberInfoActionContext.Provider, { value: actions, children: children }) }));
};
export default MemberInfoProvider;
export const useMemberInfo = () => useContext(MemberInfoContext);
export const useMemberInfoAction = () => {
    const value = useContext(MemberInfoActionContext);
    if (value === null) {
        throw new Error('MemberInfoAction 에러');
    }
    return value;
};
