import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css, styled } from 'styled-components';
import color from '@Styles/color';
import { SIZE } from '@Constants/style';
const CircularProgress = ({ size = 'medium', circleColor = color.white, $style }) => {
    return (_jsxs(StyledCircularProgress, { size: size, "$style": $style, "$circleColor": circleColor, children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] }));
};
export default CircularProgress;
const StyledCircularProgress = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    position: absolute;
    border-radius: 50%;
    animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    ${({ size = 'medium', $circleColor, $style }) => css `
      width: ${SIZE[size]};
      height: ${SIZE[size]};

      border: 2px solid ${$circleColor};
      border-color: ${$circleColor} transparent transparent transparent;

      ${$style}
    `}
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes loading-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
