import { jsx as _jsx } from "react/jsx-runtime";
import { createPortal } from 'react-dom';
import { styled } from 'styled-components';
import usePreventScroll from '@Hooks/common/usePreventScroll';
import color from '@Styles/color';
const Modal = ({ children, closeModal }) => {
    usePreventScroll();
    const onClickBackdrop = () => {
        closeModal();
    };
    const preventCloseModal = (event) => {
        event.stopPropagation();
    };
    return createPortal(_jsx(Backdrop, { onClick: onClickBackdrop, children: _jsx(ModalContainer, { onClick: preventCloseModal, children: children }) }), document.getElementById('modal-root'));
};
export default Modal;
const Backdrop = styled.div `
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.4);

  z-index: 5;
`;
const ModalContainer = styled.div `
  width: 500px;

  padding: 20px;
  border-radius: 8px;

  background-color: ${color.white};

  overflow-y: auto;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;
