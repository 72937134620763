import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import color from '@Styles/color';
import { ROUTES_PATH } from '@Constants/routes';
import ResetIcon from '@Assets/icons/ResetIcon';
import { ApiError } from '@Errors/index';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';
const ErrorFallback = ({ error, resetErrorBoundary, layoutHeight = '100vh' }) => {
    const errorMessage = error instanceof ApiError ? error.message : '서버에 문제가 발생했습니다.';
    return (_jsxs(Layout, { height: layoutHeight, children: [_jsx(Typography, { variant: "h3", children: "\uBB38\uC81C\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4." }), _jsx(Typography, { variant: "p3", children: errorMessage }), _jsxs(Button, { "$block": false, size: "small", variant: "secondary", onClick: resetErrorBoundary, children: [_jsx(ResetIcon, {}), " \uB2E4\uC2DC \uC2DC\uB3C4\uD558\uAE30"] }), _jsx(Link, { to: ROUTES_PATH.landing, onClick: resetErrorBoundary, children: "\uD648\uC73C\uB85C \uC774\uB3D9\uD558\uAE30" })] }));
};
export default ErrorFallback;
const Layout = styled.section `
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  p {
    display: flex;
    align-items: center;

    gap: 10px;
  }

  a {
    color: ${color.blue[500]};
    text-decoration: underline;
  }

  button > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
