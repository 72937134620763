import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css, styled } from 'styled-components';
import Typography from '@Components/common/Typography/Typography';
import color from '@Styles/color';
import StudyEffectGuide from '../StudyEffectGuide/StudyEffectGuide';
import StudyStepGuide from '../StudyStepGuide/StudyStepGuide';
const GuideSection = () => {
    return (_jsxs(Layout, { children: [_jsxs(Introduce, { children: [_jsx(Typography, { variant: "h2", children: "\uD558\uB8E8\uC2A4\uD130\uB514 \uD559\uC2B5 \uC0AC\uC774\uD074" }), _jsxs(Typography, { variant: "p1", fontWeight: "500", "$style": css `
            word-break: keep-all;
          `, children: ["\uD55C \uC0AC\uC774\uD074\uB9C8\uB2E4 ", _jsx("span", { children: "\uBAA9\uD45C \uC124\uC815 \uB2E8\uACC4" }), ", ", _jsx("span", { children: "\uD559\uC2B5 \uB2E8\uACC4" }), ", ", _jsx("span", { children: "\uD68C\uACE0 \uB2E8\uACC4" }), "\uB85C \uAD6C\uC131\uB418\uC5B4 \uC788\uC73C\uBA70,", _jsx("br", {}), "\uC138 \uAC00\uC9C0 \uB2E8\uACC4\uB97C \uC9E7\uC740 \uC8FC\uAE30\uB85C \uC5EC\uB7EC\uBC88 \uBC18\uBCF5\uD558\uC5EC \uD559\uC2B5\uD569\uB2C8\uB2E4."] })] }), _jsx(StudyStepGuide, {}), _jsx(StudyEffectGuide, {})] }));
};
export default GuideSection;
const Layout = styled.section `
  display: flex;
  flex-direction: column;
  gap: 180px;

  padding: 135px 0;
`;
const Introduce = styled.div `
  display: flex;
  flex-direction: column;
  gap: 20px;

  text-align: center;

  span:nth-child(1) {
    color: ${color.blue[500]};
  }

  span:nth-child(2) {
    color: ${color.red[500]};
  }

  span:nth-child(3) {
    color: ${color.teal[500]};
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 28px;
    }

    p {
      font-size: 20px;
    }
  }
`;
