import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';
import Button from '@Components/common/Button/Button';
import Typography from '@Components/common/Typography/Typography';
import color from '@Styles/color';
import { ROUTES_PATH } from '@Constants/routes';
import ChatIcon from '@Assets/icons/ChatIcon';
import GoogleIcon from '@Assets/icons/GoogleIcon';
const GOOGLE_AUTH_REDIRECT_URI_PARAMETER = '/auth?provider=google';
const KAKAO_AUTH_REDIRECT_URI_PARAMETER = '/auth?provider=kakao';
const LoginModalContents = () => {
    const baseUri = `${window.location.protocol}//${window.location.host}`;
    const googleOAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&response_type=code&redirect_uri=${baseUri}${GOOGLE_AUTH_REDIRECT_URI_PARAMETER}`;
    const kakaoOAUthUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${baseUri}${KAKAO_AUTH_REDIRECT_URI_PARAMETER}&response_type=code`;
    return (_jsxs(Layout, { children: [_jsxs(Typography, { variant: "h1", "$style": css `
          display: inline;

          font-size: 2.4rem;
          font-weight: 200;
        `, children: [_jsx(Emphasis, { children: "\uD558\uB8E8" }), "\uC2A4\uD130\uB514 \uB85C\uADF8\uC778"] }), _jsxs(ButtonContainer, { children: [_jsx("a", { href: googleOAuthUrl, children: _jsxs(GoogleOAuthLoginButton, { variant: "outlined", children: [_jsx(GoogleIcon, {}), _jsx("span", { children: "\uAD6C\uAE00\uB85C \uB85C\uADF8\uC778" })] }) }), _jsx("a", { href: kakaoOAUthUrl, children: _jsxs(KakaoOAuthLoginButton, { variant: "outlined", children: [_jsx(ChatIcon, { color: color.black }), _jsx("span", { children: "\uCE74\uCE74\uC624\uB85C \uB85C\uADF8\uC778" })] }) }), _jsxs(DividedContainer, { children: [_jsx(DividedLine, {}), _jsx("span", { children: "\uB610\uB294" }), _jsx(DividedLine, {})] }), _jsx(NonMemberLoginButton, { children: _jsx(Link, { to: `${ROUTES_PATH.auth}?provider=guest`, children: "\uBE44\uD68C\uC6D0\uC73C\uB85C \uB85C\uADF8\uC778" }) })] })] }));
};
export default LoginModalContents;
const Layout = styled.div `
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;

  padding: 40px 0px;
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 360px;

  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;
const OAutLoginButton = styled(Button) `
  position: relative;

  border-radius: 8px;

  font-size: 1.8rem;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 32px;

    width: 30px;
    height: 30px;

    display: flex;
    margin: auto 0;
  }
`;
const GoogleOAuthLoginButton = styled(OAutLoginButton) `
  border: 1px solid ${color.neutral[300]};

  color: ${color.black};
`;
const KakaoOAuthLoginButton = styled(OAutLoginButton) `
  border: none;

  color: rgba(0, 0, 0, 0.85);
  background-color: ${color.brand.kakao};

  &:hover {
    &:enabled {
      background-color: ${color.brand.kakao};
    }
  }
`;
const Emphasis = styled.span `
  color: ${color.blue[500]};
`;
const DividedContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${color.neutral[600]};
`;
const DividedLine = styled.div `
  width: 40%;
  height: 1px;

  background-color: ${color.neutral[300]};
`;
const NonMemberLoginButton = styled.button `
  margin: 0 auto;

  a {
    text-decoration: underline;
    color: ${color.neutral[500]};
  }
`;
