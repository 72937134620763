const color = {
    white: '#ffffff',
    black: '#000000',
    yellow: '#fcd34d',
    brand: {
        kakao: '#FEE500',
    },
    red: {
        200: '#cf8080',
        300: '#c86d6e',
        400: '#c15b5b',
        500: '#ba4849',
        600: '#a94041',
        700: '#96393a',
        800: '#843232',
    },
    teal: {
        200: '#55b2ba',
        300: '#47a7ae',
        400: '#3f959c',
        500: '#38848a',
        600: '#317378',
        700: '#296166',
        800: '#225054',
    },
    neutral: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#e5e5e5',
        300: '#d4d4d4',
        400: '#a3a3a3',
        500: '#737373',
        600: '#525252',
        700: '#404040',
        800: '#262626',
        900: '#171717',
        950: '#0a0a0a',
    },
    blue: {
        50: '#eff6ff',
        100: '#dbeafe',
        200: '#bfdbfe',
        300: '#93c5fd',
        400: '#60a5fa',
        500: '#3b82f6',
        600: '#2563eb',
        700: '#1d4ed8',
        800: '#1e40af',
        900: '#1e3a8a',
        950: '#172554',
    },
};
export default color;
