import http from './httpInstance';
export const requestGetMemberListRecord = (memberId, page, size, startDate, endDate) => {
    if (startDate && endDate)
        return http.get(`/view/study-records?memberId=${memberId}&page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}&sort=createdDate,desc`);
    return http.get(`/view/study-records?memberId=${memberId}&page=${page}&size=${size}&sort=createdDate,desc`);
};
export const requestGetMemberCalendarRecord = (memberId, startDate, endDate) => http.get(`/view/calendar/study-records?memberId=${memberId}&startDate=${startDate}&endDate=${endDate}`);
export const requestGetStudyParticipants = (studyId) => http.get(`/studies/${studyId}/participants`);
export const requestGetParticipantRecordContents = (studyId, participantId) => http.get(`/studies/${studyId}/contents?participantId=${participantId}`);
export const requestPostGuestLogin = () => http.post(`/auth/guest`);
export const requestPostOAuthLogin = (provider, code) => http.post(`/auth/login`, {
    body: JSON.stringify({ oauthProvider: provider, code }),
});
export const requestPostLogout = () => http.post(`/auth/logout`);
export const requestGetMemberInfo = () => http.get(`/me`);
export const requestGetStudyInfo = async (studyId) => {
    const { data: studyInfo } = await http.get(`/studies/${studyId}`);
    return studyInfo;
};
export const requestGetMemberPlan = async (studyId, participantId, cycle) => {
    const { data } = await http.get(`/studies/${studyId}/contents?participantId=${participantId}&cycle=${cycle}`);
    return data.content[0].plan;
};
export const requestWritePlan = (studyId, participantId, plan) => http.post(`/studies/${studyId}/contents/write-plan`, {
    body: JSON.stringify({ participantId, plan: plan }),
});
export const requestWriteRetrospect = (studyId, participantId, retrospect) => http.post(`/studies/${studyId}/contents/write-retrospect`, {
    body: JSON.stringify({ participantId, retrospect: retrospect }),
});
export const requestPostCreateStudy = async (studyName, totalCycle, timePerCycle) => {
    const response = await http.post(`/studies`, {
        body: JSON.stringify({ name: studyName, totalCycle, timePerCycle }),
    });
    const locationHeader = response.headers.get('Location');
    const studyId = locationHeader?.split('/').pop();
    return { studyId };
};
export const requestGetAuthenticateParticipationCode = async (participantCode) => {
    const response = await http.get(`/studies?participantCode=${participantCode}`);
    return response.data;
};
export const requestGetCheckParticipants = async (studyId, memberId) => {
    const response = await http.get(`/temp/studies/${studyId}/participants?memberId=${memberId}`);
    return response.data;
};
export const requestPostRegisterParticipants = (nickname, studyId, memberId) => http.post(`/studies/${studyId}/participants`, {
    body: JSON.stringify({ memberId, nickname }),
});
export const requestDeleteParticipant = (studyId, participantId) => http.delete(`/studies/${studyId}/participants/${participantId}`);
export const requestGetParticipantCode = async (studyId) => {
    const response = await http.get(`/participant-codes?studyId=${studyId}`);
    return response.data.participantCode;
};
export const requestGetParticipant = async (studyId, memberId) => {
    const response = await http.get(`/studies/${studyId}/participants?memberId=${memberId}`);
    return response.data.participants[0];
};
export const requestGetLobbyInfo = async (studyId) => {
    const response = await http.get(`/waiting?studyId=${studyId}`);
    return response.data;
};
export const requestPostNextStep = (studyId) => http.post(`/studies/${studyId}/next-step`);
export const requestGetMemberSubmitStatus = (studyId) => http.get(`/submitted?studyId=${studyId}`);
export const requestGetCurrentStep = (studyId) => http.get(`/progress?studyId=${studyId}`);
