import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from 'styled-components';
import Typography from '../Typography/Typography';
const URL = {
    github: 'https://github.com/woowacourse-teams/2023-haru-study',
    feedback: 'https://docs.google.com/forms/d/e/1FAIpQLSdwvz3y9xYc9PHCLw1LiaLB8TGfGao91cVs_NwERHSV9c5Mfg/viewform',
};
const Footer = () => {
    return (_jsxs(Layout, { children: [_jsx(Typography, { variant: "p3", fontSize: "12px", children: "\uC6B0\uC544\uD55C\uD14C\uD06C\uCF54\uC2A4 5\uAE30 \uD558\uB8E8\uC2A4\uD130\uB514" }), _jsx(Typography, { variant: "p3", fontSize: "12px", children: "Copyright \u00A9 2023 \uD558\uB8E8\uC2A4\uD130\uB514 - All rights reserved." }), _jsxs(LinkContainer, { children: [_jsx("a", { target: "_blank", href: URL.github, rel: "noreferrer", children: _jsx(Typography, { variant: "p3", fontSize: "12px", children: "Github" }) }), _jsx("a", { target: "_blank", href: URL.feedback, rel: "noreferrer", children: _jsx(Typography, { variant: "p3", fontSize: "12px", children: "\uC0AC\uC6A9\uC790 \uD53C\uB4DC\uBC31" }) })] })] }));
};
export default Footer;
const Layout = styled.footer `
  width: 100%;
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LinkContainer = styled.div `
  display: flex;
  gap: 20px;

  margin-top: 10px;

  p {
    text-decoration: underline;
  }
`;
