import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from 'styled-components';
import Typography from '@Components/common/Typography/Typography';
import LandingButton from '../LandingButton/LandingButton';
const StartSection = () => {
    return (_jsxs(Layout, { children: [_jsx(Typography, { variant: "h4", children: "\uD558\uB8E8\uC2A4\uD130\uB514\uB97C \uC2DC\uC791\uD574\uBCFC\uAE4C\uC694?" }), _jsx(LandingButton, {})] }));
};
export default StartSection;
const Layout = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  padding: 50px 0px 160px;

  @media screen and (max-width: 768px) {
    padding: 60px 0px 120px;
  }

  h4 {
    font-weight: 500;

    @media screen and (max-width: 768px) {
      font-size: 2.4rem;
    }
  }
`;
