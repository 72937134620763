import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { css, styled } from 'styled-components';
import Typography from '@Components/common/Typography/Typography';
import color from '@Styles/color';
import { DefaultSkeletonStyle } from '@Styles/common';
import UserProfileIcon from '@Assets/icons/UserProfileIcon';
const MenuTrigger = ({ loginType, imageUrl, name }) => {
    const [profileImageLoading, setProfileImageLoading] = useState(true);
    const displayName = loginType === 'guest' ? name.toUpperCase() : name;
    const triggerImage = loginType === 'guest' || !imageUrl ? (_jsx(GuestProfileWrapper, { children: _jsx(UserProfileIcon, { color: color.neutral[800] }) })) : (_jsx(UserProfileImage, { src: imageUrl, alt: "\uC0AC\uC6A9\uC790 \uD504\uB85C\uD544 \uC774\uBBF8\uC9C0", "$isLoading": profileImageLoading, onLoad: () => setProfileImageLoading(false) }));
    return (_jsxs(Layout, { children: [_jsx(Typography, { variant: "p3", children: displayName }), triggerImage] }));
};
export default MenuTrigger;
const Layout = styled.div `
  justify-self: flex-end;
  display: flex;
  gap: 10px;

  align-items: center;

  p {
    font-size: 2rem;
    font-weight: 500;
  }

  svg {
    width: 34px;
    height: 34px;
  }
`;
const UserProfileImage = styled.img `
  width: 36px;
  height: 36px;

  border-radius: 50%;

  ${({ $isLoading }) => css `
    ${$isLoading && DefaultSkeletonStyle}
  `}
`;
const GuestProfileWrapper = styled.div `
  padding: 2px;
  background-color: ${color.neutral[100]};
  border-radius: 50%;
`;
