import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { ThemeProvider, styled } from 'styled-components';
import Typography from '@Components/common/Typography/Typography';
import color from '@Styles/color';
import GlobalStyles from '@Styles/globalStyle';
import { lightTheme } from '@Styles/theme';
import { ROUTES_PATH } from '@Constants/routes';
const NotFound = () => {
    return (_jsxs(ThemeProvider, { theme: lightTheme, children: [_jsx(GlobalStyles, {}), _jsxs(Layout, { children: [_jsx(PageStatus, { children: "404" }), _jsx("div", { children: _jsxs(Typography, { variant: "p3", children: ["\uC694\uCCAD\uD55C \uD398\uC774\uC9C0\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.", _jsx("br", {}), "\uC785\uB825\uD558\uC2E0 \uC8FC\uC18C\uB97C \uB2E4\uC2DC \uD55C\uBC88 \uD655\uC778\uD574\uC8FC\uC138\uC694."] }) }), _jsx(Link, { to: ROUTES_PATH.landing, children: "\uD648\uC73C\uB85C \uC774\uB3D9\uD558\uAE30" })] })] }));
};
export default NotFound;
const Layout = styled.div `
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  p {
    text-align: center;
  }

  a {
    color: ${color.blue[500]};
    text-decoration: underline;
  }
`;
const PageStatus = styled.div `
  font-size: 6rem;
  font-weight: 500;
  color: ${color.neutral[600]};
`;
