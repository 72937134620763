import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { css, styled } from 'styled-components';
import color from '@Styles/color';
import ReportIcon from '@Assets/icons/ReportIon';
const BUG_REPORTING_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLSdwvz3y9xYc9PHCLw1LiaLB8TGfGao91cVs_NwERHSV9c5Mfg/viewform';
const BugReportingLink = () => {
    const [isHoverIcon, setIsHoverIcon] = useState(false);
    return (_jsxs(Layout, { children: [_jsx(GuideMessage, { "$isHoverIcon": isHoverIcon, children: "\uBD88\uD3B8\uC0AC\uD56D \uD53C\uB4DC\uBC31\uD558\uAE30" }), _jsx(Link, { href: BUG_REPORTING_LINK, target: "_blank", onMouseEnter: () => setIsHoverIcon(true), onMouseLeave: () => setIsHoverIcon(false), children: _jsx(ReportIcon, { color: "rgba(34,34,34)" }) })] }));
};
export default BugReportingLink;
const Layout = styled.div `
  display: flex;
  align-items: center;
  gap: 10px;
`;
const GuideMessage = styled.p `
  font-size: 1.4rem;

  color: ${color.neutral[600]};

  ${({ $isHoverIcon }) => css `
    opacity: ${$isHoverIcon ? '1' : '0'};
  `}

  transition: opacity 0.15s ease;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const Link = styled.a `
  cursor: pointer;

  background-color: ${color.neutral[100]};

  padding: 12px;
  border-radius: 14px;

  svg {
    width: 24px;
    height: 24px;
  }
`;
