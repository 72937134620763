import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from 'styled-components';
import Button from '@Components/common/Button/Button';
import Typography from '@Components/common/Typography/Typography';
import color from '@Styles/color';
const Alert = ({ message, closeModal, onClose }) => {
    const handleClose = () => {
        closeModal();
        onClose?.();
    };
    return (_jsxs(Layout, { children: [_jsx(Typography, { variant: "p3", children: message }), _jsx(CloseButton, { variant: "outlined", size: "x-small", "$block": false, onClick: handleClose, children: "\uD655\uC778" })] }));
};
export default Alert;
const Layout = styled.div `
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const CloseButton = styled(Button) `
  width: fit-content;
  align-self: flex-end;

  color: ${color.blue[500]};
  border: none;

  &:hover:enabled {
    background-color: ${color.blue[50]};
  }
`;
