import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useState } from 'react';
import Modal from '@Components/common/Modal/Modal';
import Alert from '@Components/common/Modal/Template/Alert';
import Confirm from '@Components/common/Modal/Template/Confirm';
export const ModalContext = createContext(null);
const ModalProvider = ({ children }) => {
    const [modalContents, setModalContents] = useState(null);
    const isOpen = Boolean(modalContents);
    const openModal = useCallback((modalContents) => setModalContents(modalContents), []);
    const closeModal = useCallback(() => setModalContents(null), []);
    const openAlert = useCallback((message, onClose) => setModalContents(_jsx(Alert, { message: message, closeModal: closeModal, onClose: onClose })), [closeModal]);
    const openConfirm = useCallback((message, onConfirm, onCancel) => setModalContents(_jsx(Confirm, { message: message, closeModal: closeModal, onConfirm: onConfirm, onCancel: onCancel })), [closeModal]);
    const value = {
        isOpen,
        openModal,
        openAlert,
        openConfirm,
        closeModal,
    };
    return (_jsxs(ModalContext.Provider, { value: value, children: [children, isOpen && _jsx(Modal, { closeModal: closeModal, children: modalContents })] }));
};
export default ModalProvider;
export const useModal = () => {
    const value = useContext(ModalContext);
    if (value === null) {
        throw new Error('Modal 에러');
    }
    return value;
};
