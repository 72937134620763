import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useState } from 'react';
import Notifications from '@Components/common/Notifications/Notifications';
export const NotificationContext = createContext(null);
const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState(null);
    const send = ({ type = 'default', message = '' }) => setNotifications((prev) => {
        if (!prev)
            return [{ type, message, id: Date.now() }];
        return [...prev, { type, message, id: Date.now() }];
    });
    const remove = useCallback((removedId) => setNotifications((prev) => {
        if (!prev)
            return null;
        return prev.filter(({ id }) => id !== removedId);
    }), []);
    const value = {
        send,
    };
    return (_jsxs(NotificationContext.Provider, { value: value, children: [children, notifications && _jsx(Notifications, { notifications: notifications, remove: remove })] }));
};
export default NotificationProvider;
export const useNotification = () => {
    const value = useContext(NotificationContext);
    if (!value) {
        throw new Error('Notification 에러');
    }
    return value;
};
